import { graphql } from 'gatsby';
import {
  arrayOf, bool, shape, string,
} from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { Content } from '../../common/content';
import { Carousel } from '../../common/carousel';
import { FluidPicture } from '../../common/picture';
import { useAuth } from '../../../hooks/useAuth';
import { useDownload } from '../../../hooks/useDownload';
import { pictureFluidType } from '../../../types';
import './downloads.scss';

const bem = new BEMHelper({
  name: 'content-downloads',
});

export const Download = ({
  title,
  file: [file],
  preview: [preview],
  mailinglist: [mailinglist],
}) => {
  const { t } = useTranslation();
  const { startDownload } = useDownload({
    mailinglist,
    url: file.url,
  });

  return (
    <li {...bem('item')}>
      <button
        {...bem('button')}
        type="button"
        title={t('Click here to download')}
        onClick={startDownload}
      >
        <FluidPicture
          {...preview}
          widths={[100, 200, 400, 600, 800]}
          maxWidth={400}
          role="presentation"
          {...bem('button__preview')}
        />
        <span {...bem('button__details')}>
          <span {...bem('button__action')}>{t('Download')}</span>
          <span {...bem('button__separator')}>: </span>
          <span {...bem('button__label')}>{title}</span>
        </span>
      </button>
    </li>
  );
};

Download.propTypes = {
  title: string.isRequired,
  file: arrayOf(shape({
    url: string.isRequired,
  })).isRequired,
  preview: arrayOf(pictureFluidType).isRequired,
  mailinglist: arrayOf(shape({
    endpoint: string.isRequired,
  })),
};

Download.defaultProps = {
  mailinglist: [],
};

export const Downloads = ({
  id,
  headline,
  description,
  downloads,
}) => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const selected = downloads.filter(({ isPublic }) => isPublic || isAuthenticated);

  if (selected.length === 0) {
    // There are no visible downloads available, so we don't need to render the
    // whole component...
    return null;
  }

  return (
    <Content id={id} {...bem()}>
      <h2 {...bem('headline')}>{headline}</h2>
      {description && (
        <p {...bem('description')}>{description}</p>
      )}
      <Carousel
        {...bem('downloads')}
        tagName="ul"
      >
        {selected.map(({ slug, ...download }) => (
          <Download key={slug} {...download} />
        ))}
      </Carousel>
      { !isAuthenticated && (
      <p>{ t('Please sign in to see all documents') }</p>
      )}
    </Content>
  );
};

Downloads.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  description: string,
  downloads: arrayOf(shape({
    title: string.isRequired,
    slug: string.isRequired,
    file: arrayOf(shape({
      url: string.isRequired,
    })).isRequired,
    preview: arrayOf(pictureFluidType).isRequired,
    isPublic: bool.isRequired,
    mailinglist: arrayOf(shape({
      headline: string.isRequired,
      description: string.isRequired,
      endpoint: string.isRequired,
    })).isRequired,
  })).isRequired,
};

Downloads.defaultProps = {
  description: undefined,
};

export const fragment = graphql`
  fragment DownloadsContentFragment on CraftGQL_pageContents_downloads_BlockType {
    id
    headline
    description
    downloads {
      ...on CraftGQL_downloads_downloads_Entry {
        title
        slug
        file: downloadFile {
          url
        }
        preview: downloadPreview {
          ...Picture
          fluid_0: url(width: 100, mode: "crop", position: "") # 0.25
          fluid_1: url(width: 200, mode: "crop", position: "") # 0.5
          fluid_2: url(width: 400, mode: "crop", position: "") # max width
          fluid_3: url(width: 600, mode: "crop", position: "") # 1.5
          fluid_4: url(width: 800, mode: "crop", position: "") # 2
        }
        isPublic: downloadIsPublic
        mailinglist: downloadMailinglist {
          ...on CraftGQL_mailinglists_mailinglists_Entry {
            headline: mailinglistHeadline
            description: mailinglistDescription
            endpoint: mailinglistEndpoint
          }
        }
      }
    }
  }
`;
